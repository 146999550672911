<template lang="pug">
Alignment(
  :label="labelOverride || $t('horizontalAlignment')"
  v-model="align"
  :options="alignOptions"
  :layout="layout"
  :editMobile="editMobile"
  vertical
)
</template>
<script>
  import Alignment from '../../Alignment/Alignment.vue';

  export default {
    components: {
      Alignment,
    },
    props: {
      labelOverride: {
        type: String,
        default: null,
      },
      value: {
        type: String,
      },
      editMobile: {
        type: Boolean,
        default: false,
      },
      vertical: {
        type: Boolean,
        default: false,
      },
      textAlign: {
        type: Boolean,
        default: false,
      },
      layout: {
        type: String,
        default: 'col',
        options: ['row', 'col'],
        validator: (value) => {
          return ['row', 'col'].includes(value);
        },
      },
    },
    computed: {
      align: {
        get() {
          return this.value;
        },
        set(v) {
          this.$emit('input', v);
        },
      },
      alignOptions() {
        if (this.vertical) {
          return [
            { text: 'top', value: 'top' },
            { text: 'center', value: 'center' },
            { text: 'bottom', value: 'bottom' },
          ];
        }

        if (this.textAlign) {
          return [
            { text: 'flex-start', value: 'left' },
            { text: 'center', value: 'center' },
            { text: 'flex-end', value: 'right' },
          ];
        }

        return [
          { text: 'left', value: 'left' },
          { text: 'center', value: 'center' },
          { text: 'right', value: 'right' },
        ];
      },
    },
  };
</script>
